import cn from 'classnames';
import LockIcon from '@assets/img/LockIcon.png';
import { WFPIcon } from '@assets/svg/icons';
import { HubLogo, UNMobilityLogo } from '@assets/svg/logos';
import { HeaderVariant } from '@common/interfaces';
import { Button } from '@components';
import './Header.styles.scss';

interface HeaderProps {
  activeLang?: string;
  isOffice?: boolean;
  languages?: string[];
  officeUrl?: string | null;
  username?: string;
  variant?: HeaderVariant;
  logout?: () => void;
  onLangChange?: (value: string) => void;
  onLogoClick?: () => void;
}

const Header = ({
  activeLang,
  isOffice,
  languages,
  officeUrl = '',
  username = '',
  variant = HeaderVariant.DEFAULT,
  logout,
  onLangChange,
  onLogoClick,
}: HeaderProps) => {
  const handleLogoKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') onLogoClick?.();
  };

  const renderLanguageSwitcher = () => (
    <div className="ddo-header-language">
      <p>Language</p>
      <div className="ddo-header-language-switcher">
        {languages?.map((lang) => (
          <Button
            key={lang}
            className={lang === activeLang ? 'active' : undefined}
            text={lang}
            variant="link"
            onClick={() => onLangChange?.(lang)}
          />
        ))}
      </div>
    </div>
  );

  const renderDigitalOffice = () => (
    <div className="ddo-header-digital-office">
      <img src={LockIcon} alt="UN Mobility" />
      <a href={officeUrl!}>Digital Office</a>
    </div>
  );

  if (variant === HeaderVariant.MODEST) {
    return (
      <header className="ddo-header ddo-header-modest">
        <div className="ddo-header-user-panel">
          <div className="ddo-header-user-status">
            <div>
              Hello <b>{username}</b>
            </div>
            <Button className="btn-logout" text="Logout" variant="link" onClick={logout} />
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className={cn('ddo-header', { 'digital-office': isOffice })}>
      <div
        aria-label="UN Mobility Logo"
        className="ddo-header-logo"
        role="button"
        tabIndex={0}
        onClick={() => onLogoClick?.()}
        onKeyDown={handleLogoKeyDown}
      >
        {isOffice ? <UNMobilityLogo /> : <HubLogo />}
      </div>

      <div className="ddo-header-user-panel">
        {isOffice && <WFPIcon width="50" height="42" />}

        {languages && renderLanguageSwitcher()}

        {!isOffice && officeUrl && renderDigitalOffice()}

        <div className="ddo-header-user-status">
          <div>
            Hello <b>{username}</b>
          </div>
          <Button className="btn-logout" text="Logout" variant="link" onClick={logout} />
        </div>
      </div>
    </header>
  );
};

export default Header;
