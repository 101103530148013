/* istanbul ignore file */
import { useTranslation } from 'react-i18next';
import { differenceInHours, differenceInMinutes, format, formatDistanceStrict } from 'date-fns';
import { EventContentArg } from '@fullcalendar/react';
import { HoverCard } from '@mantine/core';
import cn from 'classnames';

import { Button } from '@components';
import {
  DriveEditStatus,
  PlDrive,
  PlDriver,
  PlVehicle,
  ResViewType,
  ShuttleStop,
  TransferType,
} from '@common/interfaces';
import { DateFormat } from '@common/types';
import { formatDateString } from '@common/utils';
import { Drive, MSN, Pax, Plus, Shuttle, Vehicle, WarningIcon } from '@assets/svg/icons';
import { getDriveStateIcon } from '../../utils';
import '../../MobilityPlannerPage.styles.scss';

type EventContentProps = {
  drivers: PlDriver[];
  event: EventContentArg;
  isEditProcess: boolean;
  resType: ResViewType;
  showShuttle: boolean;
  vehicles: PlVehicle[];
  onDoubleClick: (event: EventContentArg) => void;
  onScheduleClick: (event: PlDrive) => void;
};

const EventContent = ({
  drivers,
  event,
  isEditProcess,
  resType,
  showShuttle,
  vehicles,
  onDoubleClick,
  onScheduleClick,
}: EventContentProps) => {
  const { t } = useTranslation();
  const {
    event: { end, extendedProps, start, title },
    view,
  } = event;
  const {
    bookingRefCode,
    bookingTransferType,
    driverAgency,
    driverEmail,
    driverFirstName,
    driverId,
    driverLastName,
    driverPhone,
    dropoffLocation,
    dropoffTown,
    editableState,
    focalPointFirstName,
    focalPointLastName,
    isConflicting,
    isShuttleEvent,
    passengerAgency,
    passengerEmail,
    passengerFirstName,
    passengerLastName,
    passengerPax,
    passengerPhone,
    passengerRemarks,
    pickupLocation,
    pickupTown,
    shuttleConfig,
    state,
    vehicleId,
    vehicleLabel,
    workflowDescription,
    workflowDropoffDate,
    workflowDropoffTime,
    workflowPickupDate,
    workflowPickupTime,
  } = extendedProps;

  const isEventDragging = document.querySelector('.fc-dragging-event');

  const viewType = view.type?.includes('Day') ? 'Day' : 'Week';
  const isEventShort = start && end && differenceInHours(end, start) < 2;
  // Info: calendar fix which has an issue with 15-min events, and displayed it a little wider
  const isMinEventTime = start && end && differenceInMinutes(end, start) === 15;
  const isWeekView = viewType === 'Week';

  const isDisabled =
    resType === ResViewType.Vehicle
      ? (vehicles.find((i) => i.id === vehicleId) as PlVehicle)?.isDisabled
      : (drivers.find((i) => i.id === driverId) as PlDriver)?.isDisabled;

  const showScheduleBtn =
    !isDisabled &&
    !isEditProcess &&
    !isWeekView &&
    !(bookingTransferType === TransferType.Shuttle && !isShuttleEvent);

  const eventContent = (
    <div
      className={cn('fc-event-content', {
        'event-size-15': !isWeekView && isMinEventTime,
        'event-week-view': isWeekView,
      })}
      data-testid={!state ? 'mobility-planner-event-preview' : 'mobility-planner-event'}
      onDoubleClick={() => onDoubleClick(event)}
    >
      {isWeekView && !isShuttleEvent ? (
        <>
          {resType === ResViewType.Driver ? (
            <div>
              <div className="icon">
                <Vehicle />
              </div>
              {vehicleLabel}
            </div>
          ) : (
            <div>
              <div className="icon">
                <Drive />
              </div>
              {driverFirstName} {driverLastName}
            </div>
          )}
          <div>{start && format(start, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}</div>
          <div>{end && format(end, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}</div>
        </>
      ) : null}

      {!isWeekView && isEventShort && !isShuttleEvent ? (
        <div className="drive-status">{getDriveStateIcon(state)}</div>
      ) : null}

      {!isWeekView && !isEventShort && !isShuttleEvent ? (
        <>
          <div className="drive-status">{getDriveStateIcon(state)}</div>
          {passengerPax ? (
            <>
              <div>
                <div className="icon">
                  <Pax />
                </div>
                {passengerPax}
              </div>
              <div>
                <div className="icon">
                  <MSN />
                </div>
                {passengerFirstName} {passengerLastName}
              </div>
            </>
          ) : null}

          {bookingTransferType !== TransferType.Shuttle && (
            <div>
              <div className="icon">
                <Drive />
              </div>
              {driverFirstName} {driverLastName}
            </div>
          )}
        </>
      ) : null}

      {isShuttleEvent && (
        <>
          <div>
            <Shuttle /> {title}
          </div>
          {!isWeekView && (
            <ul className="shuttle-stops">
              {shuttleConfig?.stops?.map((i: ShuttleStop) => (
                <li key={`${i.location}-${i.pickupTime}`} />
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );

  const eventPopoverContent = (
    <div className="fc-event-popover drive">
      {isConflicting && (
        <div className="drive-conflicting">
          <WarningIcon />
          <span>
            {t('common.recurring.conflictMsg1')} <br /> {t('common.recurring.conflictMsg2')}
          </span>
        </div>
      )}

      {!isShuttleEvent && (
        <div className="drive-schedule">
          <span>
            {t('planner.driveScheduledFor')}
            <b>{` ${formatDistanceStrict(new Date(start as Date), new Date(end as Date))}`}</b>
          </span>
          <span>{bookingRefCode ? `Ref code: ${bookingRefCode}` : null}</span>
        </div>
      )}

      {isWeekView ? (
        <div className="drive-info">
          <div className="column">
            <section>
              {!driverFirstName ? (
                <p className="title">{t('planner.noDriverInformationAvailable')}</p>
              ) : (
                <p>
                  {resType === ResViewType.Driver ? (
                    <>
                      <span className="title">{t('common.vehicle')}: </span> {vehicleLabel}
                    </>
                  ) : (
                    <>
                      <span className="title">{t('common.driver')}: </span> {driverFirstName}&nbsp;
                      {driverLastName}
                    </>
                  )}
                </p>
              )}
            </section>

            {isShuttleEvent && (
              <section>
                <p>
                  <span className="title">{t('common.email')}: </span>&nbsp;
                  {driverEmail}
                </p>
              </section>
            )}

            {!isShuttleEvent && (
              <>
                <section>
                  <p>
                    <span className="title">{t('planner.pickUp')}: </span>&nbsp;
                    {start && format(start, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}
                  </p>
                </section>
                <section>
                  <p>
                    <span className="title">{t('planner.dropOff')}: </span>&nbsp;
                    {end && format(end, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}
                  </p>
                </section>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="drive-info">
          <div className="column">
            {resType === ResViewType.Driver && vehicleLabel ? (
              <section>
                <p className="title">{t('common.vehicle')}</p>
                <p>
                  <span className="label">{t('mobility.plateNumber')}:</span>
                  {vehicleLabel?.split('|')[1] || ''}
                </p>
                <p>
                  <span className="label">{t('common.vehicle')}:</span>
                  {vehicleLabel?.split('|')[0] || ''}
                </p>
              </section>
            ) : null}

            <section>
              {!driverFirstName ? (
                <p className="title">{t('planner.noDriverInformationAvailable')}</p>
              ) : (
                <>
                  <p className="title">{t('common.driver')}</p>
                  {resType === ResViewType.Driver ? (
                    <p>
                      <span className="label">{t('common.agency')}: </span>
                      {driverAgency}
                    </p>
                  ) : (
                    <p>
                      <span className="label">{t('common.name')}: </span>
                      {driverFirstName} {driverLastName}
                    </p>
                  )}
                  <p>
                    <span className="label">{t('common.email')}:</span>
                    {driverEmail}
                  </p>
                  <p>
                    <span className="label">{t('common.phoneNumber')}:</span>
                    {driverPhone}
                  </p>
                </>
              )}
            </section>

            {!isShuttleEvent && (
              <section>
                {!passengerPax ? (
                  <p className="title">{t('planner.noPassengers')}</p>
                ) : (
                  <>
                    <p className="title">{t('common.passenger')}</p>
                    <p>
                      <span className="label">{t('common.name')}:</span>
                      {passengerFirstName} {passengerLastName}
                    </p>
                    {editableState !== DriveEditStatus.Grey && (
                      <>
                        <p>
                          <span className="label">{t('common.email')}:</span>
                          {passengerEmail}
                        </p>

                        <p>
                          <span className="label">{t('common.phoneNumber')}:</span>
                          {passengerPhone}
                        </p>
                      </>
                    )}
                    <p>
                      <span className="label">{t('common.agency')}:</span>
                      {passengerAgency}
                    </p>
                    <p>
                      <span className="label">{t('common.remarks')}:</span>
                      {passengerRemarks}
                    </p>
                    <p>
                      <span className="label">{t('planner.numberOfPax')}:</span>
                      {passengerPax}
                    </p>
                  </>
                )}
              </section>
            )}
          </div>

          <div className={cn('column', { 'column-flex': shuttleConfig?.id })}>
            <section>
              <p className="title">{t('planner.pickUp')}</p>
              <p>
                <span className="label">{t('common.town')}:</span>
                {pickupTown}
              </p>
              <p>
                <span className="label">{t('mobility.location')}:</span>
                {pickupLocation}
              </p>
              <p>
                <span className="label">{t('common.when')}:</span>
                {start && format(start, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}
              </p>
              {!isShuttleEvent && (
                <p>
                  <span className="label">{t('planner.planned')}:</span>
                  {workflowPickupDate
                    ? `${workflowPickupDate} - 
                    ${
                      workflowPickupTime &&
                      formatDateString(
                        workflowPickupTime,
                        DateFormat.ApiTimeFull,
                        DateFormat.ApiTime,
                      )
                    }`
                    : null}
                </p>
              )}
            </section>
            <section>
              <p className="title">{t('planner.dropOff')}</p>
              <p>
                <span className="label">{t('common.town')}:</span>
                {dropoffTown}
              </p>
              <p>
                <span className="label">{t('mobility.location')}:</span>
                {dropoffLocation}
              </p>
              <p>
                <span className="label">{t('common.when')}:</span>
                {end && format(end, `${DateFormat.ApiDateAlt} - ${DateFormat.ApiTime}`)}
              </p>
              {!isShuttleEvent && (
                <p>
                  <span className="label">{t('planner.planned')}:</span>
                  {workflowDropoffDate
                    ? `${workflowDropoffDate} - 
                    ${
                      workflowDropoffTime &&
                      formatDateString(
                        workflowDropoffTime,
                        DateFormat.ApiTimeFull,
                        DateFormat.ApiTime,
                      )
                    }`
                    : null}
                </p>
              )}
            </section>
            {!!state && (
              <section>
                <p className="title">{t('common.status')}</p>
                <p className="capitalize">{state?.replace(/_/g, ' ')}</p>
              </section>
            )}
            {workflowDescription ? (
              <section>
                <p className="title">{t('planner.workflow')}</p>
                <p>{workflowDescription}</p>
              </section>
            ) : null}
            {focalPointFirstName && focalPointLastName ? (
              <section>
                <p className="title">{t('planner.focalPoint')}</p>
                <p>{`${focalPointFirstName} ${focalPointLastName}`}</p>
              </section>
            ) : null}
          </div>
        </div>
      )}

      {!isWeekView && shuttleConfig?.id && (
        <ul className="shuttle-stops">
          {shuttleConfig?.stops?.map((i: ShuttleStop) => (
            <li key={`${i.location}-${i.pickupTime}`}>
              <div>{i.town}</div>
              <div>{i.location}</div>
              <span>{i.pickupTime}</span>
            </li>
          ))}
        </ul>
      )}

      {showShuttle && showScheduleBtn && (
        <Button
          className="btn drive-add-btn"
          leftIcon={<Plus />}
          type="button"
          variant="icon"
          onClick={() => onScheduleClick(event.event.extendedProps as PlDrive)}
        />
      )}
    </div>
  );

  return (
    <HoverCard
      classNames={{ dropdown: 'fc-event-dropdown' }}
      arrowSize={10}
      openDelay={300}
      shadow="md"
      withArrow
      withinPortal
      zIndex="90"
    >
      <HoverCard.Target>{eventContent}</HoverCard.Target>
      {!isEventDragging ? <HoverCard.Dropdown>{eventPopoverContent}</HoverCard.Dropdown> : null}
    </HoverCard>
  );
};

export default EventContent;
